(function () {
    'use strict';

    angular.module('aerosApp')
        .directive('aflAutoConvertUnitInput', aflAutoConvertUnitInput);

    aflAutoConvertUnitInput.$inject = [];

    function aflAutoConvertUnitInput() {
        var directive = {
            restrict: 'E',
            templateUrl: '/static/javascript/directives/afl-modal-carousel/afl-auto-convert-unit-input/afl-auto-convert-unit-input.html',
            controller: aflAutoConvertUnitInputController,
            replace: true,
            scope: {
                visible: '=',
                editable: '=',
                model: '=',
                altValue: '=',
                fullDotName: '=',
                fieldDescription: '=',
                baseUnitCaption:'=',
                altUnitCaption:'=',
                baseDecimalPlaces:'=',
                altDecimalPlaces:'=',
                baseStep:'=',
                altStep:'=',
                converterToAltUnit:'=',
                converterToBaseUnit:'=',
                required: '=',
                fieldChangeEventHandler: '&'
            },
            link: function(scope, element, attributes, controller) {

            }
        };

        return directive;
    }

    aflAutoConvertUnitInputController.$inject = ['$scope'];

    function aflAutoConvertUnitInputController($scope) {
        // Setup the pattern matching. Used in the ng-pattern directive to enforce the number format we want.

        $scope.altPattern = "-?[0-9]+";
        if ('undefined' !== typeof $scope.altDecimalPlaces && $scope.altDecimalPlaces > 0) {
            $scope.altPattern = "-?[0-9]+(\.[0-9]{1,"+$scope.altDecimalPlaces+"})?";
        }
        $scope.basePattern = "-?[0-9]+";
        if ('undefined' !== typeof $scope.baseDecimalPlaces && $scope.baseDecimalPlaces > 0) {
            $scope.basePattern = "-?[0-9]+(\.[0-9]{1,"+$scope.baseDecimalPlaces+"})?";
        }

        // We have only dB/km min/max/default values in the toolsDefinition.json file. We will do the conversion here
        // to determine our alternate unit min/max/default values.
        $scope.altMin = convertToAltUnitCheckFirst($scope.fieldDescription.min);
        $scope.altMax = +$scope.converterToAltUnit($scope.fieldDescription.max)
            .toFixed($scope.altDecimalPlaces);
        $scope.altDefault = +$scope.converterToAltUnit($scope.fieldDescription.default)
            .toFixed($scope.altDecimalPlaces);

        // Our base value is typically set, but if it is not make sure it has a valid default. There is one
        // scenario this currently happens for. The defaults come from the generic tools and None is chosen for
        // receive cable. Its dependant option for 'value' does not exist and does not get a default in this case.
        if ('undefined' === typeof $scope.model[$scope.fullDotName]) {
            $scope.model[$scope.fullDotName] = $scope.fieldDescription.default;
        }
        // Setup our alternate value now based on what we have in the model.
        convertToAltUnit();

        // If we have fields for both values we might want to swap their order in the UI. Set this up based on
        // the input
        $scope.swapValue = "floatUtilNoSwap";
        if ('undefined' !== typeof $scope.fieldDescription.swapFields && $scope.fieldDescription.swapFields) {
            $scope.swapValue = 'floatUtilSwap';
        }

        angular.extend($scope, {
            convertToAltUnit: convertToAltUnit,
            convertToBaseUnit: convertToBaseUnit
        });

        function convertToAltUnit() {
            $scope.altValue[$scope.fullDotName] = convertToAltUnitCheckFirst($scope.model[$scope.fullDotName]);
        }

        function convertToBaseUnit() {
            $scope.model[$scope.fullDotName] = +$scope.converterToBaseUnit($scope.altValue[$scope.fullDotName])
                .toFixed($scope.baseDecimalPlaces);
        }

        function convertToAltUnitCheckFirst(valueToConvert) {
            var tempValue = +$scope.converterToAltUnit(valueToConvert).toFixed($scope.altDecimalPlaces);
            // Due to rounding, there are scenarios where we can set an alternate value too low, such that it
            // won't reach our base minimum on re-conversion. Account for that here. We saw this specifically with
            // launch cable length/receive cable length as we use fewer decimal places on the the alternate units.
            var testBaseMin = +$scope.converterToBaseUnit(tempValue).toFixed($scope.baseDecimalPlaces);
            while (testBaseMin < $scope.fieldDescription.min) {
                tempValue = parseFloat(tempValue) + parseFloat($scope.altStep);
                testBaseMin = +$scope.converterToBaseUnit(tempValue).toFixed($scope.baseDecimalPlaces);
            }
            return tempValue;
        }
    }
})();
